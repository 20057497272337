import React, { useContext } from "react";

import Header from "../components/Header";
import Hero from "../components/Hero";

import Benefits from "../components/Benefits";
import Collaboration from "../components/Collaboration";
import Services from "../components/Services";
import Pricing from "../components/Pricing";
import Roadmap from "../components/Roadmap";
import Footer from "../components/Footer";

import ButtonGradient from "../assets/svg/ButtonGradient";
import HomeProducts from "../components/HomeProducts";
import ScrollToTop from "../components/ScrollToTop";

const HomePage = () => {
  return (
    <div title="Home - G-Cosmos Web">
      <div className="pt-[4.75rem] lg:pt-[5.25rem] overflow-hidden">
        <Header />
        <Hero />
        <Benefits />
        <HomeProducts />
        <Collaboration />
        <Services />
        <Pricing />
        <Roadmap />
        <Footer />
        <ScrollToTop />
        <ButtonGradient />
      </div>
    </div>
  );
};

export default HomePage;
