import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { disablePageScroll, enablePageScroll } from "scroll-lock";
import gcLogo from "../assets/gclogo.png";
import searchIcon from "../assets/searchIcon.png";
import { navigation } from "../constants";
import Button from "./Button";
import MenuSvg from "../assets/svg/MenuSvg";
import { HamburgerMenu } from "./design/Header";
import { useState } from "react";
import { useAuth } from "../context/auth";
import toast from "react-hot-toast";
import { useCart } from "../context/cart";
import { Badge } from "antd";
import { cartIcon } from "../assets/index";

const Header = () => {
  const navigate = useNavigate();
  const [auth, setAuth] = useAuth();
  const [cart] = useCart();
  const location = useLocation();
  const [openNavigation, setOpenNavigation] = useState(false);

  const toggleNavigation = () => {
    if (openNavigation) {
      setOpenNavigation(false);
      enablePageScroll();
    } else {
      setOpenNavigation(true);
      disablePageScroll();
    }
  };

  const handleClick = () => {
    if (!openNavigation) return;

    enablePageScroll();
    setOpenNavigation(false);
  };

  const handleLogout = (e) => {
    e.preventDefault();
    try {
      const confirmPayment = window.confirm(
        "Are you sure you want to log out of G-COSMOS WEB?"
      );
      if (!confirmPayment) {
        return; // Cancel the function if not confirmed
      }
      setAuth({
        ...auth,
        user: null,
        token: "",
      });
      localStorage.removeItem("auth");
      toast.success("Logout Successful");
      navigate("/");
    } catch (error) {
      console.log(error);
      toast.error("Disconnect failed");
    }
  };

  const getDashboardRoute = (role) => {
    switch (role) {
      case 940:
        return "admin";
      case 1:
        return "business";
      case 2:
        return "digitalCreator";
      case 3:
        return "manager";
      case 4:
        return "client";
      default:
        return "client";
    }
  };

  return (
    <div
      className={`fixed top-0 left-0 w-full z-[99]  border-b border-n-6 lg:bg-n-8/50 lg:backdrop-blur-sm ${
        openNavigation ? "bg-n-8" : "bg-n-8/50 backdrop-blur-sm"
      }`}
    >
      <div className="flex items-center max-sm:px-3 px-10 lg:px-7.5 xl:px-10 max-lg:py-3">
        <NavLink className="block w-[12rem] xl:mr-8" to="/">
          <img
            src={gcLogo}
            className="lg:w-[110px] w-[70px]"
            alt="gcosmosweb"
          />
        </NavLink>

        <nav
          className={`${
            openNavigation ? "flex" : "hidden"
          } fixed top-[4rem] left-0 right-0 bottom-0 bg-n-8 lg:static lg:flex lg:mx-auto lg:bg-transparent`}
        >
          <div className="relative z-2 flex flex-col items-center justify-center m-auto lg:flex-row">
            {navigation.map((item) => (
              <NavLink
                key={item.id}
                to={item.url}
                onClick={handleClick}
                className={`block relative font-code text-2xl uppercase text-n-1 transition-colors hover:text-color-1 ${
                  item.onlyMobile ? "lg:hidden" : ""
                } px-6 py-4 md:py-6 lg:-mr-0.25 lg:text-xs lg:font-semibold ${
                  item.url === location.pathname
                    ? "z-2 lg:text-n-1"
                    : "lg:text-n-1/50"
                } lg:leading-5 lg:hover:text-color-1 xl:px-12`}
              >
                {item.title}
              </NavLink>
            ))}

            <NavLink className="block my-3 lg:my-0 lg:ml-8" to="/search">
              <img
                src={searchIcon}
                className="lg:w-[40px] w-[50px]"
                alt="search"
              />
            </NavLink>
            {!auth?.user ? (
              <>
                <NavLink to="/login">
                  <Button className="flex lg:hidden mb-4">Sign in</Button>
                </NavLink>
                <NavLink to="/register">
                  <Button className="flex lg:hidden">New account</Button>
                </NavLink>
              </>
            ) : (
              <>
                <NavLink to={`/dashboard/${getDashboardRoute(auth.user.role)}`}>
                  <Button className="flex lg:hidden mb-4">Dashboard</Button>
                </NavLink>
                <Button
                  className="flex lg:hidden bg-red-500/50 border-1 rounded-3xl"
                  onClick={handleLogout}
                >
                  Logout
                </Button>
              </>
            )}
          </div>

          <HamburgerMenu />
        </nav>

        {!auth?.user ? (
          <>
            <NavLink
              to="/register"
              className="button hidden mr-8 text-n-1/50 transition-colors hover:text-n-1 lg:block"
            >
              New account
            </NavLink>
            <NavLink to="/login">
              <Button className="hidden lg:flex">Sign in</Button>
            </NavLink>
          </>
        ) : (
          <>
            <li className="nav-item">
              <NavLink
                className="nav-link hidden lg:flex dropdown-toggle"
                to="#"
                role="button"
                data-bs-toggle="dropdown"
                style={{ border: "none" }}
              >
                <Button className="hidden lg:flex">
                  {auth?.user?.name.substring(0, 10)}
                </Button>
              </NavLink>
              <ul className="dropdown-menu backdrop-blur-sm mt-3">
                <li>
                  <NavLink
                    to={`/dashboard/${getDashboardRoute(auth.user.role)}`}
                    className="menu-items px-3 mb-3"
                  >
                    Dashboard
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    onClick={handleLogout}
                    className="menu-items bg-red-400 px-3"
                    to="/"
                  >
                    Logout
                  </NavLink>
                </li>
              </ul>
            </li>
          </>
        )}
        <NavLink to="/cart" className="menu-items max-sm:mr-[30px]">
          <Badge count={cart?.length} showZero offset={[10, -3]}>
            <img src={cartIcon} className="lg:w-[28px] w-[25px]" alt="cart" />
          </Badge>
        </NavLink>
        <Button
          className="ml-auto lg:hidden"
          px="px-3"
          onClick={toggleNavigation}
        >
          <MenuSvg openNavigation={openNavigation} />
        </Button>
      </div>
    </div>
  );
};

export default Header;
