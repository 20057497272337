import React, { useState, useEffect } from "react";
import Layout from "./../components/Layout/Layout";
import { useCart } from "../context/cart";
import { useAuth } from "../context/auth";
import { NavLink, useNavigate } from "react-router-dom";
import DropIn from "braintree-web-drop-in-react";

import toast from "react-hot-toast";
import "../styles/CartStyles.css";
import Button from "../components/Button";
import { cartRobo } from "../assets/index";
import Section from "../components/Section";
import Footer from "../components/Footer";
import axiosInstance from "../utils/axiosConfig";
import { TiArrowBack } from "react-icons/ti";
import { FaShareAlt } from "react-icons/fa";

const CartPage = () => {
  const [auth, setAuth] = useAuth();
  const [cart, setCart] = useCart();
  const [clientToken, setClientToken] = useState("");
  const [instance, setInstance] = useState("");
  const [loading, setLoading] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState("card");
  const navigate = useNavigate();

  //total price
  const totalPrice = () => {
    try {
      let total = 0;
      cart?.map((item) => {
        total = total + item.price;
      });
      return total.toLocaleString("fr-DZ", {
        style: "currency",
        currency: "DZD",
      });
    } catch (error) {
      console.log(error);
    }
  };

  //detele item
  const removeCartItem = (pid) => {
    try {
      let myCart = [...cart];
      let index = myCart.findIndex((item) => item._id === pid);
      myCart.splice(index, 1);
      setCart(myCart);
      localStorage.setItem("cart", JSON.stringify(myCart));
    } catch (error) {
      console.log(error);
    }
  };

  //get payment gateway token
  const getToken = async () => {
    try {
      const { data } = await axiosInstance.get("/product/braintree/token");
      setClientToken(data?.clientToken);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getToken();
  }, [auth?.token]);

  //handle payments by cerd/paypal
  const handlePayment = async () => {
    try {
      setLoading(true);
      const { nonce } = await instance.requestPaymentMethod();
      const { data } = await axiosInstance.post("/product/braintree/payment", {
        nonce,
        cart,
      });
      setLoading(false);
      localStorage.removeItem("cart");
      setCart([]);
      navigate("/categories");
      toast.success("Paiement effectué avec succès ✅ ");
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  // Function to handle CCP payment confirmation
  const handleCCPPayment = async () => {
    try {
      // Confirm payment
      const confirmPayment = window.confirm(
        `“Do you confirm that you have sent the Total amount to the CCP / BaridiMob account?”
        ------------------------------------
        "هل تؤكد أنك أرسلت المبلغ الإجمالي إلى حساب CCP / BaridiMob؟"`
      );
      if (!confirmPayment) {
        return; // Cancel the function if not confirmed
      }
      setLoading(true);
      // Prepare the order data
      const orderData = {
        products: cart.map((item) => item._id),
        payment: "Non",
        payment2: "En Attente",
      };
      // Save the order directly to the database
      await axiosInstance.post("/product/ccp/order", orderData);
      toast.success("Order placed successfully !✅");
      localStorage.removeItem("cart");
      setCart([]);
      navigate("/categories");
      setLoading(false);
    } catch (error) {
      console.log(error);
      toast.error("Order failed ❌");
      setLoading(false);
    }
  };

  // Handle back button click
  const handleBack = () => {
    navigate(-1);
  };

  // Handle share button click
  const handleShare = async () => {
    try {
      await navigator.clipboard.writeText(window.location.href);
      toast.success("Link copied successfully");
    } catch (error) {
      toast.error("Failed to copy the link");
    }
  };

  return (
    <Layout title={"Cart - Payment"}>
      <Section crosses className="max-lg:mt-[80px]">
        <div className="container pro-nav-bar lg:mb-7 max-lg:mb-[20px]">
          <Button className="text-[22px] p-3" onClick={handleBack}>
            <TiArrowBack />
          </Button>
          <Button white className="text-[20px] p-3" onClick={handleShare}>
            <FaShareAlt />
          </Button>
        </div>
        <div className="row">
          <div className="col-md-12">
            <h1 className="text-center p-2 mb-3">
              {!auth?.user
                ? "Welcome"
                : `Hello  ${auth?.token && auth?.user?.name}`}
              <p className="text-center">
                {cart?.length
                  ? `You have ${cart.length}  product(s) in your basket ${
                      auth?.token ? "" : "please login to checkout !"
                    }`
                  : "Your basket is empty"}
              </p>
            </h1>
          </div>
        </div>
        {cart?.length ? (
          <div className="container">
            <div className="col-md-12">
              <table className="table max-sm:text-[12px] ">
                <thead>
                  <tr>
                    <th>Products</th>
                    <th>Description</th>
                    <th>Price</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {cart?.map((p) => (
                    <tr key={p._id}>
                      <td>
                        <img
                          src={`https://gcosmosweb.onrender.com/api/v1/product/product-photo/${p._id}`}
                          alt={p.name}
                          width="50px"
                          height="50px"
                        />
                      </td>
                      <td>
                        <p>{p.name}</p>
                        <p>{p.description?.substring(0, 30)}...</p>
                      </td>
                      <td>{p.price}</td>
                      <td>
                        <Button
                          red
                          className="btn"
                          onClick={() => removeCartItem(p._id)}
                        >
                          Remove❌
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="col-md-12">
                <table className="table table-bordered">
                  <tbody>
                    <tr>
                      <td>Total</td>
                      <td>{totalPrice()}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            {auth?.user ? (
              <>
                <div className="col-md-12">
                  <table className="table table-bordered">
                    <tbody>
                      <tr>
                        <td>Adresse actuelle</td>
                        <td>{auth?.user?.address}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="payment-options">
                      <Button
                        white
                        className={`btn ${
                          paymentMethod === "card" ? "activePay" : ""
                        }`}
                        onClick={() => setPaymentMethod("card")}
                      >
                        Pay with Card💳 / PayPal
                      </Button>
                      <Button
                        white
                        className={`btn ${
                          paymentMethod === "ccp" ? "activePay" : ""
                        }`}
                        onClick={() => setPaymentMethod("ccp")}
                      >
                        Pay with Cash💵 / CCP📜 / BaridiMob💳
                      </Button>
                    </div>
                  </div>
                </div>
                {paymentMethod === "ccp" && (
                  <div className="row">
                    <div className="col-md-12">
                      <div className="ccp-payment mt-4">
                        <p>
                          Please send the Total amount to this CCP/BaridiMob
                          account before confirming.
                        </p>
                        <h4>CCP: 0019620421 95</h4>
                        <h4>BaridiMob: 00799999 002 279 140 142</h4>
                      </div>
                      <Button className="my-5" onClick={handleCCPPayment}>
                        Confirm ✅
                      </Button>
                    </div>
                  </div>
                )}
                {paymentMethod === "card" && (
                  <div className="row">
                    <div className="col-md-12 ">
                      <div className="card-payment ">
                        <div className="flex justify-center items-center mt-4">
                          <a className="flex justify-center items-center mr-5">
                            {" "}
                            Bank Card
                            <img
                              className="ml-2"
                              src="https://cdn3.emoji.gg/emojis/46503-card.gif"
                              width="48px"
                              height="48px"
                              alt="Card"
                            />
                          </a>
                          <a className="flex justify-center items-center">
                            PayPal
                            <img
                              className="ml-2"
                              src="https://cdn3.emoji.gg/emojis/3327-paypal.png"
                              width="40px"
                              height="40px"
                              alt="PayPal"
                            />
                          </a>
                        </div>

                        <DropIn
                          options={{
                            authorization: clientToken,
                            paypal: {
                              flow: "vault",
                            },
                          }}
                          onInstance={(instance) => setInstance(instance)}
                        />
                        <Button
                          className="my-5"
                          onClick={handlePayment}
                          disabled={
                            loading || !instance || !auth?.user?.address
                          }
                        >
                          {loading ? "Payment in progress ..." : "Pay ✅"}
                        </Button>
                      </div>
                    </div>
                  </div>
                )}
              </>
            ) : (
              <div className="mb-3">
                <Button
                  white
                  className="my-5 text-[16px]"
                  onClick={() =>
                    navigate("/login", {
                      state: "/cart",
                    })
                  }
                >
                  Log in to Pay 👤
                </Button>
              </div>
            )}
          </div>
        ) : (
          <div className="flex items-center justify-center my-5 ">
            <div className="flex flex-col items-center justify-center align-middle">
              <img src={cartRobo} className="w-[250px] mb-5" />
              <NavLink to="/categories">
                <Button>See Products</Button>
              </NavLink>
            </div>
          </div>
        )}
      </Section>
      <Footer />
    </Layout>
  );
};

export default CartPage;
